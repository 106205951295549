import {
  styled,
  styledMap,
  breakpoint,
  getFromTheme as theme,
} from "../../utils/styles"

export const Grid = styled.div`
  display: grid;

  grid-template-columns: ${styledMap`
    full: repeat(
      ${theme("dimensions.grid.full.columns")},
      ${theme("dimensions.grid.full.columns")}fr
    );
    default: repeat(
      ${theme("dimensions.grid.default.columns")},
      ${theme("dimensions.grid.default.columns")}fr
    );
  `};

  grid-column-gap: ${styledMap`
    full: ${theme("dimensions.grid.full.columnGap")};
    fuldefaultl: ${theme("dimensions.grid.default.columnGap")};
  `};

  max-width: ${styledMap`
    full: ${theme("dimensions.grid.full.width")};
    default: ${theme("dimensions.grid.default.width")};
  `};

  margin: 0 auto;
  padding: 0 48px;

  align-items: ${({ alignItems }) => (alignItems ? alignItems : "start")};

  @media (max-width: ${breakpoint("medium")}px) {
    padding: 5vw;
    margin-left: inherit;
    margin-right: inherit;
  }

  @media (max-width: ${breakpoint("small")}px) {
    padding: 24px;
  }
`

export const GridBlock = styled.div`
  grid-column: ${({ offset }) => (offset ? `${offset} /` : "")} span
    ${({ span }) => (span ? span : 5)};

  @media (max-width: ${breakpoint("medium")}px) {
    grid-column: 1 / -1;
  }
`
