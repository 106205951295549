import React, { useEffect } from "react"
import ReactMarkdown from "react-markdown"
import {
  injectIntl,
  IntlProvider,
  IntlShape,
  useIntl,
  WrappedComponentProps,
} from "gatsby-plugin-intl"
import { navigate, graphql, PageProps } from "gatsby"

import SignUp from "../components/blocks/signUp"
import BlogPhotos from "../components/images/blogPhotos"
import {
  getLocale,
  getPathName,
  localeFromLocation,
  messagesForLanguage,
} from "../utils/localized"
import { styled, breakpoint, getFromTheme as theme } from "../utils/styles"
import Seo from "../components/common/seo"
import Layout from "../components/site/layout"
import PostAuthor from "../components/pages/blog/postAuthor"
import * as T from "../components/common/typography"
import { getLocalizedProps } from "../utils/localized"
import getExcerpt from "../utils/excerpt"
import { Grid } from "../components/common/grid"
import HorizontalRule from "../components/common/horizontalRule"
import { NormalComponents } from "react-markdown/lib/complex-types"
import { SpecialComponents } from "react-markdown/lib/ast-to-react"
import { PageContent, Section } from "../components/common/layout"


const H3 = styled(T.H3)`
  line-height: 1.3;
`

const H4 = styled(T.H4)`
  line-height: 1.3;
`

const BlogParagraph = styled(T.P)`
  grid-column: 4/10;
  margin-bottom: 18px;
  line-height: 1.5;
`

const BlogExcerptParagraph = styled(BlogParagraph)`
  margin-bottom: 20px;
`

const BlogList = styled.ol`
  grid-column: 4/10;
  font-style: italic;
  font-size: ${theme("font.size.md")};
  color: ${theme("colors.text.initial")};
  line-height: ${theme("font.lineHeight.md")};
  margin-left: 2.5em;
`

const BlogTitle = styled(T.H1)`
  margin-top: 3.5rem;
  margin-bottom: 1.5rem;
  grid-column: 1/9;

  @media (max-width: ${breakpoint("medium")}px) {
    margin-top: 2rem;
    grid-column: 1/3;
  }
`

const BlogAuthor = styled(PostAuthor)`
  grid-column: 1/3;
  align-self: start;
  border-radius: 24px;

  img {
    border-radius: 24px;
  }

  @media (max-width: ${breakpoint("medium")}px) {
    margin-bottom: 1rem;
  }
`

const BlogPostContent = styled.div`
  grid-column: 3 / span 6;

  @media (max-width: ${breakpoint("medium")}px) {
    grid-column: 1 / 3;
    width: calc(100vw - 100px);
  }

  blockquote {
    opacity: 0.75;
    margin-left: 0;
    margin-top: 1.45rem;
    padding-left: 1.45rem;
  }

  ${T.H2},
  ${H3},
  ${H4},
  ${T.H5},
  ${T.H6} {
    margin: 48px 0 24px 0;
  }
`

const BlogHR = styled(HorizontalRule)`
  grid-column: 2 / span 6;
  margin: 36px 54px;
  width: unset;
`

const PostImg = styled.div`
  width: 100%;
  height: 516px;
  object-fit: cover;
  display: flex;
  overflow: hidden;

  @media (max-width: ${breakpoint("medium")}px) {
    margin-bottom: 0;
    height: 50vh;
  }
`

const PostGrid = styled(Grid)`
  margin-bottom: 4vw;

  span {
    grid-column: 2 / span 6;
  }
`

type BlogPostProps = React.PropsWithChildren<WrappedComponentProps> &
  PageProps<object, { post: any }>

const BlogPost = ({ pageContext, path }: BlogPostProps) => {
  const { post } = pageContext
  const intl = useIntl()
  const { locale } = intl

  const [title, abstract, slug, body] = getLocalizedProps(
    post,
    ["title", "abstract", "slug", "body"],
    intl.locale
  )

  useEffect(() => {
    if (path.indexOf(slug) === -1) {
      if (slug == null) {
        navigate(`/${locale}/blog/`)
      } else {
        navigate(`/${locale}/blog/${slug}`)
      }
    }
  })

  const markdownSubstitutions = {
    p: BlogParagraph,
    ul: BlogList,
    hr: BlogHR,
    ol: BlogList,
    h1: T.H2,
    h2: H3,
    h3: H4,
    h4: T.H5,
  } as Partial<
    Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents
  >

  if (path.indexOf(slug) > -1) {
    return (
      <Layout>
        <PostImg>{BlogPhotos[post.id]}</PostImg>
        <PostGrid>
          <BlogTitle fluid margin="large">
            {title}
          </BlogTitle>
          <BlogAuthor post={post} />
          <BlogPostContent>
            <BlogExcerptParagraph large>{abstract}</BlogExcerptParagraph>
            <ReactMarkdown
              children={body}
              skipHtml={false}
              components={markdownSubstitutions}
            />
          </BlogPostContent>
        </PostGrid>

        <PageContent>
          <Section t-lg h-centered b-md h-m-sm>
            <SignUp />
          </Section>
        </PageContent>
      </Layout>
    )
  } else {
    return <></>
  }
}

export const Head = ({ pageContext }) => {
  const { post, pagePath, title, abstract, pageLocale } = pageContext
  const locale = getLocale()

  const blogPostImageSrc =
    "https://storage.googleapis.com/braver-public-content-prod/" + post.photo

  return (
    <IntlProvider messages={messagesForLanguage(locale)} locale={locale}>
      <Seo
        type="article"
        title={title}
        path={pagePath}
        locale={pageLocale}
        description={getExcerpt(abstract)}
        imageUrl={blogPostImageSrc}
      />
    </IntlProvider>
  )
}

export default injectIntl(BlogPost)

export const query = graphql`
  query BlogImage($photo: String!) {
    file(relativePath: { eq: $photo }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
  }
`
