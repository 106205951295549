import React from "react"
import styled from "styled-components"

import AvatarImgFor from "../../images/avatars"
import moment from "../../../utils/moment"
import { P, H5 } from "../../common/typography"

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
`

const PostAuthor = ({ post, ...props }) => {
  const AuthorAvatarImg = AvatarImgFor(post.author)
  return (
    <Wrapper {...props}>
      <AuthorAvatarImg
        style={{ flexShrink: "0", marginRight: "10px", minWidth: "48px" }}
      />
      <div>
        <H5 margin="xsmall">
          {post.author.firstName} {post.author.lastName}
        </H5>
        <P xsmall>{moment(post.createdDate).format("LL")}</P>
      </div>
    </Wrapper>
  )
}

export default PostAuthor
