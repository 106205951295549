import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const MarieLouAvatar = () => (
  <StaticImage
    alt="Croyez-vous que nous sommes éparpillés?"
    src="../../images/avatars/marielou.jpg"
    layout="fixed"
    height={48}
    width={48}
    placeholder="none"
  />
)
const MathieuAvatar = () => (
  <StaticImage
    alt="Croyez-vous que nous sommes éparpillés?"
    src="../../images/avatars/mathieu.jpg"
    layout="fixed"
    height={48}
    width={48}
    placeholder="none"
  />
)

const AvatarImgFor = (person) => {
  if (person == null) {
    throw new Error("person not provided")
  }

  if (person === "marielou") {
    return MarieLouAvatar
  } else if (person === "mathieu") {
    return MathieuAvatar
  } else if ("firstName" in person) {
    if (person.firstName === "Mathieu") {
      return AvatarImgFor("mathieu")
    } else if (person.firstName === "Marie-Lou") {
      return AvatarImgFor("marielou")
    }
    throw new Error("person malformed")
  }
}

export default AvatarImgFor
